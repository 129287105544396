<div class="numpad-wrap alto-numpad">
  <div class="numpad-container">

    <div class="numpad">
      <div class="numpad-row">
        <div *ngFor="let key of numpadKeys[0]" class="key" (click)="onKeyPress(key)">
          <span>{{ key }}</span>
        </div>
      </div>

      <div class="numpad-row">
        <div *ngFor="let key of numpadKeys[1]" class="key" (click)="onKeyPress(key)">
          <span>{{ key }}</span>
        </div>
      </div>

      <div class="numpad-row">
        <div *ngFor="let key of numpadKeys[2]" class="key" (click)="onKeyPress(key)">
          <span>{{ key }}</span>
        </div>
      </div>

      <div class="numpad-row">
        <div *ngFor="let key of numpadKeys[3]" class="key" (click)="onKeyPress(key)">
          <span>{{ key }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
