import {Component, Output, EventEmitter  } from '@angular/core';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-numpad',
  templateUrl: `./numpad.component${environment.templateKey || ''}.html`,
  styleUrls: ['./numpad.component.scss']
})

export class NumpadComponent {
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() keypress: EventEmitter<string> = new EventEmitter<string>();
  inputValue: string = '';
  numpadKeys = [
    ['1', '2', '3'],
    ['4', '5', '6'],
    ['7', '8', '9'],
    ['*', '0', '#'],
  ];

  onKeyPress(key: string) {
    if (key === 'backspace') {
      this.inputValue = this.inputValue.slice(0, -1);
    } else {
      this.inputValue += key;
    }

    this.keypress.emit(key); 
  }


  onClose() {
    this.close.emit(); 
  }
}