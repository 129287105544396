import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { CarouselComponent } from './carousel/carousel.component';
import {RouterModule} from "@angular/router";
import { KeyboardComponent } from './keyboard/keyboard.component';
import { FormsModule } from '@angular/forms';
import { NumpadComponent } from './numpad/numpad.component';



@NgModule({
  declarations: [CarouselComponent, KeyboardComponent, NumpadComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule  
  ],
  exports: [
    TranslateModule,
    CarouselComponent,
    CommonModule,
    KeyboardComponent,
    NumpadComponent
  ]
})
export class SharedModule { }
