import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Carousel} from "bootstrap";

@Component({
  selector: 'app-carousel',
  templateUrl: `./carousel.component.html`,
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {


  env: any = environment;
  @ViewChild("carousel") carouselElement!: ElementRef<HTMLElement>;

  carouselRef!: Carousel;
  constructor() { }

  ngOnInit(): void {
  }


  ngAfterViewInit() {
    this.carouselRef = new Carousel(this.carouselElement.nativeElement, {
      slide: true
    });
  }
}
