import { BaseModel } from './base.model';
import { AddressModel } from './address.model';
import { ImageModel } from './image.model';
import { ContactModel } from './contact.model';

export class CompanyModel extends BaseModel {
    static TYPE_TENANT = 'tenant';
    static TYPE_FM_PROVIDER = 'fm-provider';
    static MODAL_LOCATION_ALL = 0;
    static MODAL_LOCATION_SUCCESS_CHECKIN = 1;
    static MODAL_LOCATION_DIAL_TENANT = 2;

    id?: string;
    name!: string;
    address?: AddressModel;
    image?: ImageModel;
    receptionNumber?: string;
    hasPhoneNumber?: boolean;
    receptionText?: string;
    receptionDisplay?: string;
    isOnDisplay?: number;
    contacts?: ContactModel[];

    get childModels() {
        return {
            image: ImageModel,
            address: AddressModel,
            contacts: ContactModel,
        };
    }

    get phone() {
        return this.receptionNumber;
    }

    get logoUri() {
        return this.image?.originalUrl || '/assets/img/placeholder-image.png'
    }

    get thumbUrl() {
        return this.logoUri;
    }

    get hasContacts() {
        return !!this.contacts?.length;
    }
}