import { Component, Output, EventEmitter, ViewChild, ElementRef  } from '@angular/core';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-keyboard',
  templateUrl: `./keyboard.component${environment.templateKey || ''}.html`,
  styleUrls: ['./keyboard.component.scss']
})


export class KeyboardComponent {
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() keyPressed: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('inputField') inputField!: ElementRef ;
  @ViewChild(KeyboardComponent) keyboardComponent!: KeyboardComponent;


  inputValue: string = ''; 

  isNumeric: boolean = false; 


  keyRows = [
  [
    { char: 'Q', symbol: '1' }, { char: 'W', symbol: '2' }, { char: 'E', symbol: '3' },
    { char: 'R', symbol: '4' }, { char: 'T', symbol: '5' }, { char: 'Y', symbol: '6' },
    { char: 'U', symbol: '7' }, { char: 'I', symbol: '8' }, { char: 'O', symbol: '9' },
    { char: 'P', symbol: '0' } 
  ],
  [
    { char: 'A', symbol: '@' }, { char: 'S', symbol: '#' }, { char: 'D', symbol: '$' },
    { char: 'F', symbol: '&' }, { char: 'G', symbol: '*' }, { char: 'H', symbol: '(' },
    { char: 'J', symbol: ')' }, { char: 'K', symbol: "'" }, { char: 'L', symbol: '"' }
  ],
  [
    { char: 'Z', symbol: '%' }, { char: 'X', symbol: '-' }, { char: 'C', symbol: '+' },
    { char: 'V', symbol: '=' }, { char: 'B', symbol: '/' }, { char: 'N', symbol: ';' },
    { char: 'M', symbol: ':' }, { char: ',', symbol: '!' }, { char: '.', symbol: '?' }
  ]
];


  onKeyPress(key: string) {
    if (key === 'backspace') {
      this.inputValue = this.inputValue.slice(0, -1);
    } else {
      this.inputValue += key;
       this.setFocus();
    }
    this.keyPressed.emit(this.inputValue); 
  }

  setFocus() {
  setTimeout(() => {
    const inputEl = this.inputField.nativeElement as HTMLInputElement;
    inputEl.focus();
    inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
  }, 0);
}
  
  onClose() {
    this.close.emit(); 
  }

  toggleNumSymbol() {
    this.isNumeric = !this.isNumeric;

    this.keyRows = this.keyRows.map(row =>
      row.map(key => ({ char: key.symbol, symbol: key.char }))
    );
  }
}
